import React, { useState, useEffect } from "react";
import "./App.css";

const DOMAIN = "https://demo.grandart.com";
const FrontendDOMAIN = "https://grandart.com";
function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  console.log('searchQuery: ', searchQuery);
  const [searchResults, setSearchResults] = useState([]);
  console.log("file: Header.js:9 ~ Header ~ searchResults:", searchResults);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);

  // Function to update a URL parameter
  function updateURLParameter(param, value) {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.history.pushState({}, "", url.toString());
  }

  const handleSearchQueryChange = (query) => {
    setSearchQuery(query);

    if (query.length >= 1) {
      // Fetch search suggestions
      fetch(`${DOMAIN}/api/headersearch/?searchsuggetions=${query}`)
        .then((response) => response.json())
        .then((data) => {
          setSearchSuggestions(data.data.docs.map((result) => result.name));
        })
        .catch((error) => {
          console.error("Error fetching search suggestions:", error);
        });
    } else {
      setSearchSuggestions([]);
    }

    if (query.length >= 3) {
      // Fetch search results
      fetch(`${DOMAIN}/api/headersearch/?q=${query}`)
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data?.data?.docs);
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
          setSearchResults([]); // Clear search results in case of an error
        });

      // Fetch search categories
      fetch(`${DOMAIN}/api/faceted-search/category/?q=${query}`)
        .then((response) => response.json())
        .then((data) => {
          setSearchCategory(data?.data || []); // Ensure setSearchCategory gets an empty array if no data
        })
        .catch((error) => {
          console.error("Error fetching search categories:", error);
          setSearchCategory([]); // Clear search categories in case of an error
        });
    } else {
      setSearchResults([]);
      setSearchCategory([]);
    }
  };

  const handleProductClick = (productId) => {
    // Redirect to the specified URL with the "q" query parameter
    window.location.href = `${FrontendDOMAIN}${productId}`;
  };

  const handleCategoryClick = (categoryName, data) => {
    console.log(JSON.parse(data), "data");
    const categoryInfo = JSON.parse(data);
    // Redirect to the specified URL with the "q" query parameter
    window.location.href = `${FrontendDOMAIN}${categoryInfo.custom_url.url}`;
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSearchSuggestions([]); // Clear suggestions when a suggestion is clicked

    // Update the URL with the suggestion as the search parameter
    updateURLParameter("q", suggestion);
    // Redirect to the specified URL with the "q" query parameter
    window.location.href = `${FrontendDOMAIN}/search.php?q=${encodeURIComponent(
      suggestion
    )}`;
  };

  const handleSearchIconClick = () => {
    // When the search icon is clicked, update the URL with the search query
    updateURLParameter("q", searchQuery);
    window.location.href = `${FrontendDOMAIN}/search.php?q=${encodeURIComponent(
      searchQuery
    )}`;
  };

  const handleCloseClick = () => {
    setSearchQuery("");
  };

  return (
    <>
      <div className='top-search row-col'>
        <div
          className='dropdown dropdown--quickSearch is-open f-open-dropdown'
          id='quickSearch'
          aria-hidden='true'
          data-prevent-quick-search-close=''>
          <div className='container'>
            <form
              className='form'
              onSubmit={(e) => {
                e.preventDefault(); // Prevent form submission
              }}
              data-url='/search.php'
              data-quick-search-form=''>
              <fieldset className='form-fieldset'>
                <div className='form-field'>
                  <label className='is-srOnly' htmlFor='nav-quick-search'>
                    Search
                  </label>
                  <input
                    className='form-input'
                    name='nav-quick-search'
                    id='nav-quick-search'
                    data-error-message='Search field cannot be empty.'
                    placeholder='Search By Subject, Color, Room…'
                    autoComplete='off'
                    value={searchQuery}
                    onChange={(e) => handleSearchQueryChange(e.target.value)}
                  />

                  <button
                    className='search_quick_btn'
                    name='search_quick'
                    type='submit'
                    onClick={handleSearchIconClick}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='21.207'
                      height='21.207'
                      viewBox='0 0 21.207 21.207'>
                      <path
                        id='search2'
                        d='M34.833,28.933A6.967,6.967,0,1,0,27.867,35.9,6.964,6.964,0,0,0,34.833,28.933ZM40,40.5l-.6.6-6.333-6.333a7.825,7.825,0,1,1,.6-.6Z'
                        transform='translate(-19.5 -20.6)'
                        fill='#46555f'
                        stroke='#46555f'
                        strokeWidth={1}
                        fillRule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
              </fieldset>
            </form>

            <section className='quickSearchResults' data-bind='html: results' />

            <p
              role='status'
              aria-live='polite'
              className='aria-description--hidden'
              data-search-aria-message-predefined-text='product results for'
            />
            <div className='search-result'>
              {searchQuery.length > 0 && (
                <>
                  {searchQuery.length > 0 && (
                    <button
                      className='delete_quick_btn'
                      name='delete_quick'
                      onClick={handleCloseClick}>
                      X
                    </button>
                  )}
                  <div className='search-suggestions'>
                    <h2>Search Suggestions</h2>
                    {searchQuery?.length >= 3 &&
                    searchSuggestions?.length === 0 ? (
                      <ul className='no-suggestions'>
                        <li>No Search Suggestions Found</li>
                      </ul>
                    ) : (
                      <ul>
                        {searchSuggestions?.map((suggestion) => (
                          <li
                            key={suggestion}
                            onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </>
              )}
              {searchQuery.length > 0 && (
                <div className='search-category'>
                  <h2>Categories</h2>
                  {searchQuery?.length >= 3 && searchCategory?.length === 0 ? (
                    <ul className='no-suggestions'>
                      <li>No Categories Found</li>
                    </ul>
                  ) : (
                    searchCategory?.map((result) => (
                      <ul>
                        <div
                          key={result.id}
                          onClick={() =>
                            handleCategoryClick(
                              result.name,
                              result.category_data
                            )
                          }>
                          <li>{result.name}</li>
                        </div>
                      </ul>
                    ))
                  )}
                </div>
              )}
              {searchQuery.length > 0 && (
                <div className='search-products'>
                  <h2>Products</h2>
                  {(searchQuery?.length >= 3 && searchResults?.length === 0) ||
                  searchResults === undefined ||
                  searchResults === null ? (
                    <ul className='no-suggestions'>
                      <li>No Products Found</li>
                    </ul>
                  ) : (
                    searchResults?.map((result) => (
                      <ul>
                        <div
                          key={result.id}
                          className='search-result-item'
                          onClick={() =>
                            handleProductClick(result.bc_custom_url.url)
                          }>
                          <img src={result.image_url[0]} alt={result.name} />
                          <li>{result.name}</li>
                          <li>${result.price}</li>
                        </div>
                      </ul>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
